<template>
   <div id="inside" style="">
    <div class="container">
      <div class="row">
        <div class="">
        </div>
        <div class="col-md-9">
          <div class="card rounded shadow">
            <div class="card-header text-light bg-blue" style=""><i class="fa fa-folder-open-o fa-fw mr-2"></i> Contratos negociáveis</div>
            
            <div class="card-body pb-5" style="" v-if="loadDeal">
              <div class="row">
                <div class="col-md-12 col-12">
                  <h5 class=""></h5>
                </div>
              </div>
              <div class="row">
                  <div class="justify-center" style="margin-top:5px;margin-left:0px;width:100% !important;">
                      <ProgressLoading v-bind:loading="loadDeal"/>
                  </div>
              </div>
            </div>

            <div class="card-body pb-5" v-if="!loadDeal">
              <div class="row">
                <div class="col-md-12 col-12">
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-1">
                </div>
                <div class="col-12 col-md-10" style="">
                  <h1 class="text-secondary">Negociar</h1>
                  <hr>
                  <h5 class="">{{ contract.AccountNotes }}</h5>
                  <p class="m-0 text-info">Valor:</p>
                  <h5>R$ {{ formatValue(contract.value) }}</h5>
                  <p class="m-0">Contrato: {{ contract.contractNumber }}</p>
                  <p class="text-info">Data: {{ formatData(contract.dueDate, "YYYY-MM-DD", "L") }}</p>
                  <h6 class="">Detalhes:</h6>
                  <p>{{ contract.AccountNotes }}</p>
                  <hr>
                  <h4 class="">Selecione uma proposta:</h4>
                  <div class="row">
                    <div class="col-md-7 col-10">
                      <select class="form-control mx-auto mt-3 mb-4" v-model="selectDeal">
                        <option v-bind:value="acordo" v-for="acordo in negociacao.Deals" :key="acordo.type">{{acordo.installments}}x R$ {{formatValue(acordo.value)}}</option>
                      </select>
                    </div>
                  </div>
                  <button type="button" @click="showDigitalLine" class="btn btn-secondary mr-1 mb-1">Pagar com boleto</button><button type="submit" class="btn mr-1 mb-1 btn-secondary">Outro meio</button>

                   <div class="col-md-12" style="margin-top:20px;" v-if="digitalLine != null">
                     <p class="text-secondary" style="margin-bottom:0px;">Linha digitável para pagamento:</p>
                     <p class="text-info">{{digitalLine}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3" style="">
          <ContentMenu></ContentMenu>
        </div>
      </div>
      <div class="row" style="">
        <div class="col-md-12 col-15 my-3" style="">
          <hr> <a class="btn btn-link mx-auto mb-2" @click="voltar"><i class="fa fa-chevron-left fa-fw"></i>Voltar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
import ProgressLoading from '../../../modules/auth/components/ProgressLoading.vue';
import ContentMenu from '../components/ContentMenu.vue';

export default {
    name: "HistoricoPagamentos",
     components: {
      ProgressLoading,
      ContentMenu
    },
    data () {
        return {
            loadDeal: false,
            negociacao: null,
            contract :null,
            selectDeal:null,
            digitalLine:null,
            links: [
                { icon: 'fa fa-folder-open-o fa-fw mr-2', text: 'Contratos Negociáveis', route: '/dashboard/minhas-dividas' },
                { icon: 'fa fa-folder-open-o fa-fw mr-2', text: 'Boletos Ativos', route: '/dashboard/meus-boletos' },
                { icon: 'fa fa-handshake-o fa-fw mr-2', text: 'Acordos', route: '/dashboard/meus-acordos' },
                { icon: 'fa fa-fw mr-2 fa-user-o', text: 'Cadastro', route: '/dashboard/cadastro' }
            ]
        }
    },
    methods: {
        formatValue,
        formatData,

        ...mapActions('minhasDividas', ['ActionDividaNegociar']),

        deactivated() {
            this.divida = null;
            this.negociacao = null;
            this.selectDeal = null;
            this.digitalLine = null;
        },
        voltar() {
             this.$router.push({ name: 'dashboard' });
        },
        showDigitalLine(){
          this.digitalLine = this.selectDeal.linhaDigitavel;
        }
    },
    async created() {
        this.contract = this.$route.params.contractInfo;
        if (this.negociacao === null){
            this.loadDeal = true;
            const response = await this.ActionDividaNegociar(this.contract.contractNumber);
        
            this.negociacao = response.data.results;
            this.selectDeal = this.negociacao.Deals[0];
            this.loadDeal = false;
        }
    },
    computed: {
    }
};
</script>
